import {useCookieStore} from "~/store/cookie";
import {useAuthStore} from "~/store/auth";

export default defineNuxtRouteMiddleware(async (to, from) => {
    const config = useRuntimeConfig();
    const cookieStore = useCookieStore();

    const next = to.fullPath;
    const url = '/signin?next=' + next;
    // slight change
    if (! cookieStore.token) {
        return navigateTo(url);
    }

    const authStore = useAuthStore();
    await authStore.userDetails(config);
    if (authStore.fetchError) {
        return navigateTo(url);
    }
})